import React from 'react';
import './styles.css';

import pin from '../../assets/pin.png'

export default function Block({ title, info, link }) {
  return (
    <div className="block-container">
      <strong>
        {title}
      </strong>
      <span>
        {info || "Não informado"}

        {link ? <a href={link} target="_blank" rel="noreferrer">
          <img src={pin} alt="" />
        </a> : <span />}

      </span>
    </div >
  );
}