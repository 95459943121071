import React from 'react';

import './styles.css';

export default function Footer() {
  return (
    <nav className="footer-container">
      <small>© 2022 RBT Internet. Todos os direitos reservados.</small>
    </nav>
  );
}