import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import './styles.css';

import api from '../../services/api';

import Header from '../../components/Header';
import Block from '../../components/Block';
import Map from '../../components/Map';
import Footer from '../../components/Footer';

export default function Site() {
  const [siteInfo, setSiteInfo] = useState({});

  let { curSite } = useParams();
  window.document.title = `${curSite.toUpperCase()} - Informações`

  useEffect(() => {
    async function fetchData() {
      const result = await api.post(`/sites`, { site: curSite.toUpperCase() });
      console.log(result);
      setSiteInfo(result.data);
    }
    fetchData();
  }, [])

  return (

    <div className="site-container">
      <Header />
      <h4>{siteInfo.title}</h4>
      <div className="site-content">
        <Block title="Descrição" info={siteInfo.description} />
        <Block title="Locador" info={siteInfo.name} />
        <Block title="Telefones" info={`${siteInfo.phone}${siteInfo.cell_phone_1 ? " | " + siteInfo.cell_phone_1 : ''}`} />
        <Block title="Endereço" info={siteInfo.street} />
        <Block title="Complemento" info={siteInfo.complement} />
        <Block title="Bairro" info={siteInfo.neighborhood} />
        <Block title="Cidade" info={siteInfo.city} />
        <Block title="Estado" info={siteInfo.state} />
        <Block title="Coordenadas" info={`${siteInfo.lat}, ${siteInfo.lng}`}
          link={`https://www.google.com/maps/?q=${siteInfo.lat}+${siteInfo.lng}`}
        />
      </div>
      <Map lat={siteInfo.lat} lng={siteInfo.lng} />
      <Footer />
    </div >
  )
}